import React from "react";

import "./VideoPlayer.scss"

type PropsType = {
  url: string
}

export const VideoPlayer: React.FC<PropsType> = ({ url }) => {
  return <div className="videoPlayer">
  <video controls src={url} />
  </div>
}