import { User } from "../../../types/User.type";
import { UserRoleEnum } from "../../../types/UserRole.enum";
import { IconAudio } from "../../Icons/IconAudio";
import { IconEdit } from "../../Icons/IconEdit";
import { IconGenHistory } from "../../Icons/IconGenHistory";
import { IconImgGenerate } from "../../Icons/IconImgGenerate";
import { IconSpeedtest } from "../../Icons/IconSpeedtest";
import { IconSupport } from "../../Icons/IconSupport";

// type MenuItem = Required<MenuProps>['items'][number];


export type MenuItemSrcType = {
  key: string,
  title: string
  path: string
  icon?: any,
  children?: MenuItemSrcType[],
  type?: 'group',
  user?: User,
  roles: UserRoleEnum[]
  show: boolean
}
export const lkMenuItems: MenuItemSrcType[] =[
  {
    key: '/lk',
    title: 'Инструменты', 
    path: '/lk',
    icon: IconSpeedtest, 
    roles: [UserRoleEnum.Member],
    show: true
  },
  {
    key: '/lk/text',
    title: 'Работа с текстом', 
    path: '/lk/text',
    icon: IconEdit, 
    roles: [UserRoleEnum.Member],
    show: true
  },
  {
    key: '/lk/images',
    title: 'Генерация изображений', 
    path: '/lk/images',
    icon: IconImgGenerate, 
    roles: [UserRoleEnum.Member],
    show: true
  },
  {
    key: '/lk/audio/synthesis',
    title: 'Генерация аудио', 
    path: '/lk/audio',
    icon: IconAudio, 
    roles: [UserRoleEnum.Member],
    show: true
  },
  {
    key: '/lk/audio/recognition',
    title: 'Генерация аудио', 
    path: '/lk/audio',
    icon: IconAudio, 
    roles: [UserRoleEnum.Member],
    show: false
  },
  {
    key: '/lk/history',
    title:'История генераций', 
    path: '/lk/history',
    icon: IconGenHistory, 
    roles: [UserRoleEnum.Member],
    show: true
  },
  {
    key: '/lk/support/all',
    title: 'Поддержка', 
    path: '/lk/support/all',
    icon: IconSupport, 
    roles: [UserRoleEnum.Member],
    show: true
  },
  {
    key: '/lk/support/open',
    title: 'Поддержка', 
    path: '/lk/support/open',
    icon: IconSupport, 
    roles: [UserRoleEnum.Member],
    show: false
  },
  {
    key: '/lk/support/closed',
    title: 'Поддержка', 
    path: '/lk/support/closed',
    icon: IconSupport, 
    roles: [UserRoleEnum.Member],
    show: false
  },
  {
    key: '/lk/faq/',
    title: 'Справка', 
    path: '/lk/faq/',
    icon: IconSupport, 
    roles: [UserRoleEnum.Member],
    show: true
  }
]