import { Button, Checkbox, Form, Input } from "antd";
import FormItem from "antd/es/form/FormItem";
import Modal from "antd/es/modal/Modal";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import NeuronetTypeSelector from "../../../Components/common/NeuronetTypeSelector";
import TextArea from "antd/es/input/TextArea";
import { SaveNeuronetType } from "../../../types/SaveNeuronet.type";
import NeuronetOutputTypeSelector from "../../../Components/common/NeuronetOutputTypeSelector";
import UserSelector from "../../../Components/common/UserSelector";
import { NeuronetLogoUpload } from "./NeuronetLogoUpload";

type PropsType = {
  modalVisible: boolean
  editedItem: any
  closeModal: () => void
  saveData: (editedItem: any)=>Promise<boolean>
};

export const NeuronetEditor: React.FC<PropsType> = ({modalVisible, closeModal, editedItem, saveData})=>{
  const [editedItemLocal, setEditedItem] = useState<SaveNeuronetType>(editedItem)
  const ok = () => {
    saveData(editedItemLocal)
    .then(e => {
      if(e) closeModal()
    })
  }
  const cancel = ()=>{
    closeModal()
  }
  const onUploadComplete = (image: any) => {
    setEditedItem({...editedItemLocal, image: image})
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };
  const username = (editedItem.user?.profile?.first_name?editedItem.user.profile.first_name:'')
  + (editedItem.user?.profile?.last_name?' '+editedItem.user.profile.last_name:'')
  return <>
  <Modal 
    getContainer={()=>{return document.getElementById('modals_container')||document.body}}  
    title="Редактирование нейросети"
        wrapClassName="vertical-center-modal"
        open={modalVisible}
        onOk={() => ok()}
        onCancel={() => cancel()}
        footer={<>
        <Button onClick={cancel}>Отмена</Button>
        <Button type="primary" onClick={ok}>Ok</Button>
        </>}
        >
        <Form>
        <div style={{display: 'flex', justifyContent: 'center', alignItems:'center', paddingTop: '12px', paddingBottom: '8px'}}>
            Лого нейросети
          </div>
          <div style={{display: 'flex', justifyContent: 'center', alignItems:'center', paddingBottom: '18px'}}>
            <NeuronetLogoUpload value={editedItemLocal.image} onUploadComplete={onUploadComplete} />

          </div>
          <FormItem
                      {...formItemLayout}
                      label={
                        <span>
                          Название&nbsp;
                        </span>
                      }
                      hasFeedback>
                      <Input value={editedItemLocal.name} onChange={(val)=>setEditedItem({...editedItemLocal, name: val.target.value})}/>
          </FormItem>
          <FormItem {...formItemLayout}
                      label={
                        <span>
                          Тип&nbsp;
                        </span>
                      }
                      hasFeedback>
            <NeuronetTypeSelector value={editedItemLocal.type} onChange={(val)=>setEditedItem({...editedItemLocal, type: val})} />
          </FormItem>
          <FormItem {...formItemLayout}
                      label={
                        <span>
                          Генерит&nbsp;
                        </span>
                      }
                      hasFeedback>
            <NeuronetOutputTypeSelector value={editedItemLocal.output_type} onChange={(val)=>setEditedItem({...editedItemLocal, output_type: val})} />
          </FormItem>

          <FormItem {...formItemLayout}
                      label={
                        <span>
                          Показывать&nbsp;
                        </span>
                      }
                      hasFeedback>
            <Checkbox checked={editedItemLocal.visibility} onChange={(val)=>setEditedItem({...editedItemLocal, visibility: val.target.checked})} />
          </FormItem>

          <FormItem {...formItemLayout}
                      label={
                        <span>
                          Пользователь&nbsp;
                        </span>
                      }
                      hasFeedback>
            <UserSelector value={username}
              onChange={(val)=>setEditedItem({...editedItemLocal, user_id: val})} />
          </FormItem>

          <FormItem {...formItemLayout}
                      label={
                        <span>
                          О нейросети&nbsp;
                        </span>
                      }
                      hasFeedback>
            <TextArea rows={4} 
              value={editedItemLocal.about_text} 
              onChange={(val)=>setEditedItem({...editedItemLocal, about_text: val.target.value})} />
          </FormItem>

          {/* <FormItem {...formItemLayout}
                      label={
                        <span>
                          Настройки&nbsp;
                        </span>
                      }
                      hasFeedback>
            <TextArea rows={4} 
              value={editedItemLocal.data} 
              onChange={(val)=>setEditedItem({...editedItemLocal, data: val.target.value})} />
          </FormItem> */}
        </Form>
  </Modal>
  </>
}